/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "assets/style/variables";

@include mat.elevation-classes();
@include mat.app-background();
@import "maplibre-gl/dist/maplibre-gl.css";
@import "@ng-select/ng-select/themes/default.theme.css";

@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
$font: Questrial, "Helvetica Neue", sans-serif;

$palette-bleu: (
	50: #e3e1e9,
	100: #b8b3c8,
	200: #8981a4,
	300: #594e7f,
	400: #362863,
	500: #120248,
	// Origine
	600: #100241,
	700: #0d0138,
	800: #0a0130,
	900: #050121,
	A100: #675dff,
	A200: #372aff,
	A400: #0f00f6,
	A700: #0d00dd,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #ffffff,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff,
	),
);

$palette-orange: (
	50: #fbeae2,
	100: #f6cab7,
	200: #f0a787,
	300: #ea8357,
	400: #e66933,
	500: #e14e0f,
	// Origine
	600: #dd470d,
	700: #d93d0b,
	800: #d53508,
	900: #cd2504,
	A100: #fff6f5,
	A200: #e14e0f,
	A400: #ff9c8f,
	A700: #ff8575,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);

$button-custom-level: mat.m2-define-typography-level(
	$font-family: $font,
	$font-weight: 500,
	$font-size: 1rem,
	$line-height: 1,
	$letter-spacing: normal,
);

$my-primary: mat.m2-define-palette($palette-bleu, 500);
$my-accent: mat.m2-define-palette($palette-orange, A200, A100, A400);
$my-typography: mat.m2-define-typography-config(
	$font-family: $font,
	$button: $button-custom-level,
);

$my-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $my-primary,
			accent: $my-accent,
		),
		typography: $my-typography,
		density: 0,
	)
);

@include mat.all-component-themes($my-theme);
@include mat.all-component-typographies($my-typography);
@include mat.typography-hierarchy($my-typography);

.mat-mdc-menu-panel {
	max-width: 600px !important;
}

.btn-loading {
	transition: 0.1s;
	background-color: #d5d5d5 !important;
	position: relative;
	z-index: 100;
	overflow: hidden !important;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 200;
	}

	&:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 4px solid #ffffff;
		border-top-color: variables.$primary;
		animation: spinner 0.8s linear infinite;
		z-index: 300;
	}
}

.loader {
	content: "";
	box-sizing: border-box;
	width: 20px;
	height: 20px;
	margin: auto;
	border-radius: 50%;
	border: 4px solid variables.$text-lightgray;
	border-top-color: variables.$primary;
	animation: spinner 0.8s linear infinite;
	z-index: 300;
}

.link {
	cursor: pointer;
	text-decoration: underline;
}

@each $name, $color, $text-color, $text-opposite in variables.$theme-colors {
	.btn-#{$name} {
		border: none;
		text-align: center;
		margin: 0;
		min-height: 36px;
		padding: 0 16px;
		border-radius: 4px;
		font-weight: bold;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 0.8rem;
		text-decoration: none;
		background-color: $color !important;
		color: $text-color !important;

		&-reverse {
			@extend .btn-#{$name};
			background-color: $text-color !important;
			color: $color !important;
		}

		&:disabled {
			background-color: variables.$text-gray !important;
			border: none;
			color: variables.$background-white !important;
			cursor: default;
		}
	}

	.btn-stroked-#{$name} {
		@extend .btn-#{$name};
		border: 1px solid $color;
		background-color: variables.$background-white !important;
		color: $color !important;

		&.disabled {
			background-color: variables.$text-gray !important;
			border: none;
			color: variables.$background-white !important;
			pointer-events: none;
			cursor: not-allowed;
		}
	}

	.#{$name},
	text-#{$name},
	.text-#{$name},
	a.link-#{$name},
	a.link-#{$name}:visited,
	a.link-#{$name}:focus {
		color: $color;
	}

	.bg-#{$name} {
		background-color: $color !important;
		color: $text-color !important;
		font-family: $font;
		font-size: 0.8rem;
		font-weight: bold;
	}

	.alert-#{$name} {
		background-color: rgba($color, 0.3);
		color: $text-opposite;
		border-radius: 5px;
		border: 2px solid $color;
		padding: 5px;
	}

	.message-#{$name} {
		background-color: $color;
		color: $text-color;
		font-weight: bold;
		padding: 0.5em;
		border-radius: 0.3em;
		display: flex;
		gap: 0.3em;

		.mat-mdc-icon {
			flex-shrink: 0;
		}

		&-reverse {
			@extend .message-#{$name};
			background-color: $text-color !important;
			color: $color !important;
			border: 1px solid $color;
		}
	}
}

@each $name, $percentage in variables.$percentages {
	.w-#{$name} {
		width: $percentage !important;
	}

	.h-#{$name} {
		height: $percentage !important;
	}

	.br-#{$name} {
		border-radius: $percentage !important;
	}
}

@each $name, $size in variables.$sizes {
	.p-#{$name} {
		padding: $size !important;
	}
	.pt-#{$name} {
		padding-top: $size !important;
	}
	.pb-#{$name} {
		padding-bottom: $size !important;
	}
	.pl-#{$name} {
		padding-left: $size !important;
	}
	.pr-#{$name} {
		padding-right: $size !important;
	}
	.px-#{$name} {
		padding-right: $size !important;
		padding-left: $size !important;
	}
	.py-#{$name} {
		padding-top: $size !important;
		padding-bottom: $size !important;
	}

	.m-#{$name} {
		margin: $size !important;
	}
	.mt-#{$name} {
		margin-top: $size !important;
	}
	.mb-#{$name} {
		margin-bottom: $size !important;
	}
	.ml-#{$name} {
		margin-left: $size !important;
	}
	.mr-#{$name} {
		margin-right: $size !important;
	}
	.mx-#{$name} {
		margin-right: $size !important;
		margin-left: $size !important;
	}
	.my-#{$name} {
		margin-top: $size !important;
		margin-bottom: $size !important;
	}

	.b-#{$name} {
		border: $size black solid !important;
	}
	.bt-#{$name} {
		border-top: $size black solid !important;
	}
	.bb-#{$name} {
		border-bottom: $size black solid !important;
	}
	.bl-#{$name} {
		border-left: $size black solid !important;
	}
	.br-#{$name} {
		border-right: $size black solid !important;
	}
	.bx-#{$name} {
		border-right: $size black solid !important;
		border-left: $size black solid !important;
	}
	.by-#{$name} {
		border-top: $size black solid !important;
		border-bottom: $size black solid !important;
	}

	@each $color-name, $color, $text-color, $text-opposite in variables.$theme-colors {
		.b-#{$name}-#{$color-name} {
			border: $size $color solid !important;
		}
		.bt-#{$name}-#{$color-name} {
			border-top: $size $color solid !important;
		}
		.bb-#{$name}-#{$color-name} {
			border-bottom: $size $color solid !important;
		}
		.bl-#{$name}-#{$color-name} {
			border-left: $size $color solid !important;
		}
		.br-#{$name}-#{$color-name} {
			border-right: $size $color solid !important;
		}
		.bx-#{$name}-#{$color-name} {
			border-right: $size $color solid !important;
			border-left: $size $color solid !important;
		}
		.by-#{$name}-#{$color-name} {
			border-top: $size $color solid !important;
			border-bottom: $size $color solid !important;
		}
	}

	.gap-#{$name} {
		gap: $size;
	}
}

@each $name, $display in variables.$displays {
	.d-#{$name} {
		display: $display !important;
	}
}

@each $name, $align in variables.$text-alignment {
	.text-#{$name} {
		text-align: $align !important;
	}
}

.d-flex-col {
	display: flex !important;
	flex-direction: column;
}

.italic {
	font-style: italic;
}

.btn-squared,
.mat-mdc-button.btn-squared,
.btn-rounded,
.mat-mdc-button.btn-rounded {
	width: 36px;
	min-width: 36px;
	height: 36px;
	min-height: 36px;
	padding: 0;
	display: flex;
	justify-content: center;
}

.btn-rounded,
.mat-mdc-button.btn-rounded {
	border-radius: 50%;
}

html,
body {
	height: 100%;
	overflow: hidden;
}

body {
	background-color: variables.$background;
	margin: 0;
}

body,
button {
	font-family: $font;
}

th,
td {
	letter-spacing: normal;
}

.pointer {
	cursor: pointer;
}

.columns {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
}

@media (max-width: 1510px) {
	.columns {
		grid-auto-flow: row;
		grid-template-columns: none;
		row-gap: 10px;
	}
}

.mat-mdc-content {
	display: flex;
	justify-content: space-between;

	mat-mdc-panel-title,
	mat-mdc-panel-description {
		flex-grow: 0;
	}
}

.title {
	color: variables.$primary !important;
}

.nav-step .title {
	color: white !important;
}

.container {
	padding: 20px;
}

// temporary fix for expansion panels until Angular Material Issue is fixed,
// see https://github.com/angular/material2/issues/13870
mat-mdc-accordion mat-mdc-expansion-panel {
	mat-mdc-expansion-panel-header {
		height: 40px;
	}

	.mat-mdc-expansion-panel-content {
		height: 0;
	}

	&.mat-mdc-expanded {
		mat-mdc-expansion-panel-header {
			height: 64px;
		}

		.mat-mdc-expansion-panel-content {
			height: auto;
		}
	}
}

.address-change-input .mat-mdc-form-field-wrapper {
	padding-bottom: 0;
}

.mat-mdc-form-field,
.mat-mdc-slide-toggle,
.mat-mdc-slide-toggle-content,
.mat-mdc-radio-label-content,
.mat-mdc-radio-container,
.mat-mdc-list,
.mat-mdc-list-base .mat-mdc-subheader,
.mat-mdc-list-item,
.mat-mdc-checkbox {
	font-family: $font;
}

$tablet-width: 768px;
$desktop-width: 1024px;
@mixin phone {
	@media (max-width: #{$tablet-width}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

.mat-mdc-form-field-no-padding .mat-mdc-form-field-wrapper {
	padding-bottom: 0 !important;
}

.field-error {
	font-size: 12px;
	color: variables.$danger;
}

.custom-input-error {
	display: none;
	font-size: 12px;
	color: variables.$danger;
}

.custom-input-group {
	display: flex;
	flex-direction: column;

	.custom-input-action {
		display: flex;

		.custom-input {
			border-radius: 4px 0 0 4px;
			border-right: none;
			width: 100%;
		}

		button {
			border-radius: 0 4px 4px 0;
		}
	}

	.custom-input.ng-touched:invalid + .custom-input-error,
	.custom-input:focus:invalid + .custom-input-error,
	.custom-input.touched:invalid + .custom-input-error,
	.custom-input .touched:invalid + .custom-input-error {
		display: block;
	}
}

.custom-input {
	background-color: variables.$background-white;
	padding-left: 8px;
	color: variables.$text-secondary;
	height: 40px;
	border: 2px solid variables.$input-border;
	border-radius: 4px;
	font-size: 14px;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	&.ng-touched:invalid,
	&:focus:invalid,
	&.touched:invalid {
		border-color: variables.$danger;
	}

	&,
	input {
		color: variables.$text-secondary;
	}

	span {
		white-space: nowrap;
		color: rgba(variables.$text-secondary, 0.7);
	}

	input {
		background: rgba(0, 0, 0, 0);
		border: none;
		width: 100%;
		height: 100%;

		&:focus {
			border: none;
			outline: none;
		}
	}

	&:focus {
		border-color: variables.$primary;
		outline: none;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}

	&.ng-touched:invalid,
	&.custom-input-on-error,
	&.touched:invalid {
		border-color: variables.$danger;
	}

	&:disabled,
	&.disabled {
		color: variables.$text-gray;
		background-color: rgba(0, 0, 0, 0.1);
		cursor: default;
		caret-color: transparent;
	}
}

.ng-select-disabled .ng-select-container {
	color: variables.$text-gray !important;
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-input-light {
	padding-left: 8px;
	color: variables.$text-secondary;
	height: 40px;
	border: 2px solid variables.$input-border;
	border-radius: 4px;
	font-size: 14px;
	box-sizing: border-box;

	&:focus {
		border-color: variables.$primary-light;
		outline: none;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}
}

.pane {
	position: relative;
	padding: 40px;
	border-radius: 15px;
	background-color: white;
	margin-bottom: 20px;
}

.subpane {
	padding: 30px;
	border-radius: 15px;
	background-color: #f7faff;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.ng-select .ng-select-container {
	border: 2px solid variables.$input-border;
}

.ng-select.ng-select-opened > .ng-select-container {
	border-color: variables.$primary;
}

.ng-dropdown-panel {
	box-shadow: rgba(54, 92, 193, 0.3) 0px 4px 12px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	color: variables.$text-secondary !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
	background-color: variables.$select-hover;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	background-color: variables.$select-selected;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	color: variables.$text-secondary !important;
}

.ng-select .ng-select-container {
	color: variables.$text-secondary;
}

.ng-dropdown-panel.ng-select-bottom {
	margin-top: 5px;
	border-radius: 4px !important;
	border: 2px solid variables.$primary;
}

.ng-dropdown-panel.ng-select-top {
	margin-bottom: 5px;
	border-radius: 4px !important;
	border: 2px solid variables.$primary;
}

.ng-dropdown-panel-items .ng-option:first-child {
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
}

.ng-dropdown-panel-items .ng-option:last-child {
	border-bottom-left-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

.ng-select.ng-select-single .ng-select-container {
	height: 40px;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
	border-radius: 4px !important;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
	border-radius: 4px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
	border-color: variables.$primary;
	box-shadow: none;
}

.ng-select.ng-select-focused {
	border-color: yellow;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ng-sg-selected {
	background: lightblue !important;
}

.dialog-overflow .mat-mdc-dialog-container {
	overflow: visible !important;
}

.text-secondary-input {
	color: variables.$text-secondary !important;
}

.custom-tabs {
	.mat-mdc-tab-header {
		border: none !important;
		margin-bottom: 3px;
	}

	.mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab--active {
		color: variables.$primary !important;
		font-weight: bold;
	}

	.mdc-tab--active .mdc-tab__content {
		z-index: 5 !important;
		background-color: white !important;
		border-radius: 5px;
		box-shadow: variables.$primary 0px 0px 10px -5px;
	}

	.mat-mdc-tab-labels .mdc-tab__content {
		padding: 5px;
		height: 18px;
	}

	.mdc-tab-indicator {
		opacity: 0 !important;
	}

	.mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab {
		color: variables.$text-secondary;
		font-weight: bold;
		background-color: #e9f0fb;
		opacity: 1 !important;
		border: none !important;
		min-width: 100px;
		padding: 10px;
	}

	.mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab:first-child {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab:last-child {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}

.custom-sub-tabs {
	.mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab--active {
		color: variables.$primary;
		font-weight: bold;

		background-color: white;
	}

	.mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
		color: variables.$text-secondary;
		font-weight: bold;
		background-color: white;
		width: 65px;
		min-width: 65px !important;
	}

	.mdc-tab--active .mdc-tab__content {
		z-index: 5 !important;
		width: 50px;
		border-radius: 5px;
		box-shadow: variables.$primary 0px 1px 15px -5px;
	}
}

.ng-select.ng-select-single .ng-select-container {
	height: 40px;
}

.text-icon {
	display: inline-flex;
	align-items: center;
	gap: 0.2em;
}

label[required]:after,
input[required] + label:after {
	content: "*";
	color: red;
}

.candy-form {
	border-left: solid variables.$primary-light;
	padding-left: 1em;
}

.icon-button {
	border: 1px solid transparent;
	border-radius: 0.3em;
	color: white;
	background: variables.$danger;
	display: flex;
	padding: 0;
	align-items: center;
	width: 37px;
	height: 37px;
	justify-content: center;
	cursor: pointer;
}

.mat-mdc-list-base .mat-mdc-subheader {
	font-size: 1.15rem;
}

.box {
	position: relative;
	border-style: solid;
	border-width: 1px;
	border-color: variables.$text-secondary;
	border-radius: 10px;
	padding: 10px;

	.box-title {
		display: flex;
	}

	.box-add-button {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
	}
}

.help-input,
.help-input-select {
	position: relative;

	.help {
		position: absolute;
		right: 0;
		top: 0;
	}

	input {
		width: 100%;
	}

	&-select .help {
		right: 20px; // width of the caret for the dropdown
	}
}

.input-icon {
	position: relative;
	color: variables.$text-secondary;
	width: fit-content;

	input {
		padding-right: 100px;
	}

	.input-suffix {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		display: flex;
		padding-right: 5px;
	}
}

.first-connection-dialog .mat-mdc-dialog-container,
.p-0 .mat-mdc-dialog-container {
	padding: 0;
	overflow: hidden;
}

.ngx-ui-tour_backdrop {
	z-index: 999 !important;
}

.no-scroll {
	overflow: hidden !important;
}

.dialog-container {
	display: flex;
	flex-direction: column;
	max-width: 70vw;
	max-height: 90vh;
	min-width: 500px;

	&.tall {
		min-height: 70vh;
	}

	.dialog-close-icon {
		display: flex;
		justify-content: flex-end;
		padding: 0.5rem;

		.close-icon {
			cursor: pointer;
			color: variables.$text-gray;
		}
	}

	.dialog-header {
		position: relative;
		display: flex;
		padding: 1rem 2rem;
		vertical-align: center;
		text-align: center;
		justify-content: space-between;

		.dialog-close-icon {
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.close-icon {
				cursor: pointer;
				color: variables.$text-lightgray;
			}
		}
	}

	.dialog-content {
		padding: 1rem 2rem;
		overflow: auto;
		flex-grow: 1;

		.dialog-small-text {
			max-width: 500px;
			text-align: justify;
		}
	}

	.dialog-actions {
		display: flex;
		justify-content: flex-end;
		background-color: variables.$primary;
		color: white;
		padding: 1rem 2rem;
		margin-top: auto;
	}
}

.zoom {
	transform: scale(120%);
}

.step-error {
	transition: 0.3s;
	transform-origin: bottom left;
	width: fit-content;
}

.step-error-content {
	border: 0.3rem variables.$danger solid;
	border-radius: 5px;
}

.step {
	overflow: hidden;
	transition: 0.3s;
	padding: 10px 0;

	.step-content-wrapper {
		transition: 0.3s;
		height: 0;
		overflow: hidden;

		.step-content {
			padding: 20px;
		}
	}

	.step-fold {
		display: flex;
		align-items: center;

		cursor: pointer;

		.step-fold-title {
			font-weight: bold;
			font-size: 1.3rem;
			color: variables.$text-secondary;
			margin-left: 10px;
			transition: 0.3s;
		}

		.step-fold-number {
			border: 1px solid variables.$text-secondary;
			color: variables.$text-secondary;
			width: 32px;
			height: 32px;
			transition: 0.3s;
			border-radius: 50%;
			font-weight: bolder;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&:not(.selected) {
		.step-content-wrapper {
			height: 0 !important;
		}

		.step-fold {
			.step-fold-number {
				border: 1px solid variables.$text-gray;
				color: variables.$text-gray;
				width: 24px;
				height: 24px;
			}

			.step-fold-title {
				font-weight: bold;
				font-size: 1.1rem;
				color: variables.$text-gray;
			}
		}
	}
}

.not-found {
	text-align: center;

	h3 {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		font-size: 5rem;
		color: #{"#0004"};

		span {
			display: block;
			font-size: 1rem;
			color: black;
		}
	}
}

.option {
	overflow: hidden;
	text-overflow: ellipsis;
}

.expand-icon {
	transition: 0.3s;
}

.bold {
	font-weight: bold !important;
}

.small-icon {
	font-size: 16px;
	height: 16px;
	width: 16px;
}

.rotating {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

.infinite-fade {
	-webkit-animation: infinite-fade 0.5s linear infinite;
	-moz-animation: infinite-fade 0.5s linear infinite;
	-ms-animation: infinite-fade 0.5s linear infinite;
	-o-animation: infinite-fade 0.5s linear infinite;
	animation: infinite-fade 0.5s linear infinite;
}

.stopping {
	opacity: 0;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes infinite-fade /* Safari and Chrome */ {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}

@keyframes infinite-fade {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}

.checkbox-white-space-none > .mat-mdc-checkbox-layout > .mat-mdc-checkbox-label {
	white-space: normal;
}

.no-dropdown-width .ng-dropdown-panel {
	width: auto;
}

.chart {
	height: 100%;
	width: 100%;
	min-height: 300px;
}

.ultra-bold {
	font-family: $font;
	font-weight: 700;
}

.btn-icon-animate {
	overflow: hidden;
	transition: all 0.6s;
	flex-shrink: 0;
	white-space: nowrap;
	display: flex;
	align-items: center;
	max-height: 36px;
	gap: 0;

	&-text {
		max-width: 0;
		transition: all 0.6s;
		overflow: hidden;
		display: flex;

		&:before {
			content: " ";
			width: 10px;
			display: block;
		}
	}

	&-icon {
		font-size: 1rem;
		height: auto !important;
		width: auto !important;
	}

	&-image {
		max-width: 100%;
		max-height: 100%;
		height: 16px;
		width: auto;
	}

	&:hover {
		width: auto;

		.btn-icon-animate-text {
			max-width: 400px;
		}
	}
}

.flex-space {
	flex: 1 1 0px;
	width: 0;
}

.mat-mdc-slide-toggle {
	margin: {
		top: 5px;
	}
}

.mdc-form-field {
	label {
		font-size: 1.1em;
	}
}

// Remove ripple from material components
div[class^="mdc-"][class$="__ripple"],
span[class^="mdc-"][class$="__ripple"],
span[class^="mat-mdc-"][class$="-ripple"],
.mat-ripple {
	display: none;
}

.table-header th {
	color: #0d0d0d !important;
	font-size: 13px !important;
}
