$primary: var(--primary);
$primary-light: var(--primary-light);

$accent: var(--accent);
$accent2: var(--accent2);
$accent3: var(--accent3);
$accent4: var(--accent4);

$danger: var(--danger);
$warning: var(--warning);

$background: #f0f1f6;
$background-white: white;

$text-main: black;
$text-secondary: var(--text-secondary);
$text-opposite: white;
$text-login: #656567;
$text-gray: gray;
$text-lightgray: lightgray;
$lighter-gray: #f6f6f6;

$input-border: #bdbdbd;

$select-selected: #f8f7fc;
$select-hover: #f8f7fc;

$shadow-dropdown: rgba(14, 30, 37, 0.12);

//year reference colors
$absolute-objective: orangered;
$brut-consumption: green;
$correction: red;
$corrected-consumption: #67b7dc;

$theme-colors:
	"primary" $primary $text-opposite $text-main,
	"primary-light" $primary-light $text-opposite $text-main,
	"accent" $accent $text-opposite $text-main,
	"accent2" $accent2 $text-opposite $text-main,
	"accent3" $accent3 $text-opposite $text-main,
	"accent4" $accent4 $text-opposite $text-main,
	"danger" $danger $text-opposite $text-main,
	"warning" $warning $text-opposite $text-main,
	"absolute-objective" $absolute-objective $text-opposite $text-main,
	"brut-consumption" $brut-consumption $text-opposite $text-main,
	"correction" $correction $text-opposite $text-main,
	"corrected-consumption" $corrected-consumption $text-main $text-opposite,
	"text-main" $text-main $text-opposite $text-main,
	"text-secondary" $text-secondary $text-opposite $text-main,
	"text-opposite" $text-opposite $text-main $text-opposite,
	"text-gray" $text-gray $text-opposite $text-main,
	"text-lightgray" $text-lightgray $text-main $text-opposite,
	"lighter-gray" $lighter-gray $text-main $text-opposite,
	"white" white $text-main $text-opposite;

$percentages:
	"0" 0%,
	"25" 25%,
	"50" 50%,
	"75" 75%,
	"100" 100%;

$sizes:
	"0" 0,
	"1" 0.3rem,
	"2" 0.5rem,
	"3" 1rem,
	"4" 1.5rem,
	"5" 2rem,
	"auto" auto,
	"1px" 1px;

$displays:
	"block" block,
	"none" none,
	"flex" flex,
	"inline-flex" inline-flex;

$text-alignment:
	"left" left,
	"center" center,
	"right" right;
